import { Button, Collapse, CollapseProps, Form, Input, List, Space, Table, TableProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
const { Paragraph } = Typography;

interface RuleCategoryDefinition {
    category: string;
    matches: CategoryMatch[]
    aliases: CategoryAlias[]
}

interface CategoryMatch {
    name: string;
    type?: string;
}

interface CategoryAlias {
    name: string;
}

interface CategoryMatchTableRow {
    name: string;
    type?: string;
    isNew: boolean
}

export const CategoryFooter = (props: {
    createNewMatch: (category: string) => void
}) => {
    return <>
        <span>Add new description rule</span>
        <Paragraph
            editable={{
                onChange: (category: string) => props.createNewMatch(category),
            }}
        ></Paragraph>
    </>
}

export const DisplayRuleCategoryMatch = (props: {
    matches: CategoryMatch[],
    onAdd: (matchName: string) => void,
    onUpdate: (matchName: string, update: { name?: string, type?: string }) => void,
    onDelete: (matchName: string) => void,
}) => {
    const [editing, setEditing] = useState({} as { [key: string]: boolean })
    const [matches, setMatches] = useState(props.matches)

    useEffect(() => {
        setMatches(props.matches);
    }, [props.matches])

    const matchRows = matches.map(m => ({
        isNew: false,
        ...m,
    }))

    function setEditingValue(key: string, value: boolean) {
        const newEditing = { ...editing }
        newEditing[key] = value
        setEditing(newEditing)
    }

    function toggleEditing(key: string) {
        const newEditing = { ...editing }
        newEditing[key] = !editing[key]
        setEditing(newEditing)
    }

    function update(matchName: string, update: { name?: string, type?: string }) {
        if (!!update.name || update.type !== undefined) {
            props.onUpdate(matchName, update)
        }
    }

    const columns: TableProps<CategoryMatchTableRow>['columns'] = [
        {
            title: 'Description match',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => {
                return <>
                    {!!editing[name] && <Paragraph editable={{
                        onChange: (updatedName: string) => {
                            const lowercase = updatedName.toLocaleLowerCase().trim()
                            record.isNew
                                ? props.onAdd(lowercase)
                                : update(name, { name: lowercase })
                            setEditingValue(lowercase, true)
                        },
                        maxLength: 50,
                    }}>{name}</Paragraph>}
                    {!editing[name] && name}
                </>
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type, record) => <>
                {!record.isNew && !!editing[record.name] && <Paragraph editable={{
                    onChange: (updatedType: string) => update(record.name, { type: updatedType }),
                    maxLength: 50,
                }}>{type}</Paragraph>}
                {!record.isNew && !editing[record.name] && type}
            </>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => <>
                {!record.isNew && <Space size="middle">
                    <a onClick={() => toggleEditing(record.name)}>{!!editing[record.name] ? 'Done' : 'Edit'}</a>
                    <a onClick={() => props.onDelete(record.name)}>Delete</a>
                </Space>}
            </>,
        },
    ];

    return <>
        <Table<CategoryMatchTableRow> rowKey={t => t.name} columns={columns} dataSource={[...matchRows]} />
        <Button onClick={() => {
            if (!!matchRows.find(m => m.isNew)) {
                console.log('already a new row')
                return
            }
            const newMatches = [...matchRows]
            newMatches.push({
                name: '',
                isNew: true,
            })
            setMatches(newMatches)
            setEditingValue('', true)
        }} type="primary" style={{ marginBottom: 16 }}>
            Add a row
        </Button>
    </>
}

export const DisplayRuleCategoryAliases = (props: {
    aliases: CategoryMatch[],
    onDelete: (aliasName: string) => void,
}) => {
    const [aliases, setAliases] = useState(props.aliases)

    useEffect(() => {
        setAliases(props.aliases);
    }, [props.aliases])

    const columns: TableProps<CategoryAlias>['columns'] = [
        {
            title: 'Alias name',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => {
                return <>{name}</>
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => <>
                {<Space size="middle">
                    <a onClick={() => props.onDelete(record.name)}>Delete</a>
                </Space>}
            </>,
        },
    ];

    return <Table<CategoryAlias> rowKey={t => t.name} columns={columns} dataSource={[...aliases]} />
}

export const DisplayRuleCategoryDefinition = (props: {
    definition: RuleCategoryDefinition,
    onAddNewMatch: (matchName: string) => void,
    updateCategoryName: (updatedCategoryName: string) => void,
    onUpdateMatch: (matchName: string, update: { name?: string, type?: string }) => void,
    onDeleteMatch: (matchName: string) => void,
    onDeleteAlias: (aliasName: string) => void,
}) => {
    const [editingCategoryName, setEditingCategoryName] = useState(false)
    const [viewAliases, setViewAliases] = useState(false)

    const anyAliases = (props.definition.aliases || []).length > 0

    return <>
        <DisplayRuleCategoryMatch
            matches={props.definition.matches}
            onAdd={props.onAddNewMatch}
            onUpdate={props.onUpdateMatch}
            onDelete={props.onDeleteMatch}
        />
        <Button onClick={() => setEditingCategoryName(!editingCategoryName)} type="primary" style={{ marginLeft: '5px', marginBottom: 16 }}>
            {editingCategoryName ? 'Cancel Edit Category Name' : 'Edit Category Name'}
        </Button>
        {anyAliases && <Button onClick={() => setViewAliases(!viewAliases)} type="primary" style={{ marginLeft: '5px', marginBottom: 16 }}>
            {viewAliases ? 'Close Aliases' : 'View Aliases'}
        </Button>}
        {editingCategoryName && <Form layout="horizontal"
            onFinish={({ category }) => props.updateCategoryName(category)}
            labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
            <Form.Item label="Category" initialValue={props.definition.category} name="category" rules={[{ required: true, message: 'Category is required' }]}>
                <Input placeholder="Category name" />
            </Form.Item>
            <Form.Item name="submit" wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">Update Category Name</Button>
            </Form.Item>
        </Form>}
        {anyAliases && viewAliases && <DisplayRuleCategoryAliases
            aliases={props.definition.aliases}
            onDelete={alias => props.onDeleteAlias(alias)}
        />}
    </>
}

export const AddCategory = (props: {
    onAdd: (category: string) => void,
}) => {

    const [addingCategory, setAddCategory] = useState(false)

    function update(updates: { category: string }) {
        props.onAdd(updates.category)
        setAddCategory(false)
    }

    return <>
        {!addingCategory && <Button onClick={() => setAddCategory(true)} style={{ marginTop: '16px' }}>
            Add new category
        </Button>}
        {addingCategory && <>
            <h3 style={{ marginTop: '16px' }}>New Category</h3>
            <Button onClick={() => setAddCategory(false)} >
                Cancel
            </Button>
            <Form layout="horizontal"
                onFinish={({ category }) => update({ category })}
                labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
                <Form.Item label="Category" name="category" rules={[{ required: true, message: 'Category is required' }]}>
                    <Input placeholder="Category name" />
                </Form.Item>
                <Form.Item name="submit" wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type="primary" htmlType="submit">Add</Button>
                </Form.Item>
            </Form>
        </>}
    </>
}

export const DisplayCategories = (props: {
    rules: RuleCategoryDefinition[],
    updateRule: (rule: RuleCategoryDefinition, existingCategoryName?: string) => void
}) => {

    // console.log('got rules', props.rules)

    function addNewMatch(category: string, matchName: string) {
        const ruleDefinition = props.rules.find(c => c.category === category)
        if (!ruleDefinition) {
            console.log('could not find rule definition')
            return
        }

        const match = ruleDefinition.matches.find(c => c.name === matchName)
        if (!!match) {
            console.log('already exists')
            return
        }

        ruleDefinition.matches.push({
            name: matchName,
        })

        props.updateRule(ruleDefinition, undefined)
    }

    function addNewCategory(category: string) {
        const existingRuleDefinition = props.rules.find(c => c.category === category)
        if (!!existingRuleDefinition) {
            console.log('category exists')
            return
        }

        const newRuleDefinition: RuleCategoryDefinition = {
            category,
            matches: [],
            aliases: [],
        }
        props.updateRule(newRuleDefinition)
    }

    function updateMatch(category: string, currentMatchName: string, update: { name?: string, type?: string }) {
        const ruleDefinition = props.rules.find(c => c.category === category)
        if (!ruleDefinition) {
            console.log('could not find rule definition')
            return
        }

        let match = ruleDefinition.matches.find(c => c.name === currentMatchName)
        if (!match) {
            console.log(`could not find match for '${currentMatchName}'`)
            return
        }

        let hasBeenUpdated = false
        if (update.type !== undefined) {
            match.type = update.type
            hasBeenUpdated = true
        }

        if (!!update.name) {
            match.name = update.name
            hasBeenUpdated = true
        }

        if (hasBeenUpdated) {
            props.updateRule(ruleDefinition)
        }
    }

    function updateCategoryName(existingCategoryName: string, newCategoryName: string) {
        const ruleDefinition = props.rules.find(c => c.category === existingCategoryName)
        if (!ruleDefinition) {
            console.log('could not find rule definition')
            return
        }

        if (existingCategoryName !== newCategoryName) {
            ruleDefinition.category = newCategoryName
            props.updateRule(ruleDefinition, existingCategoryName)
        }
    }

    function deleteMatch(category: string, matchName: string) {
        const ruleDefinition = props.rules.find(c => c.category === category)
        if (!ruleDefinition) {
            console.log('could not find rule definition')
            return
        }

        ruleDefinition.matches = ruleDefinition.matches.filter(c => c.name !== matchName)
        console.log('deleting', ruleDefinition)

        props.updateRule(ruleDefinition)
    }

    function deleteAlias(category: string, aliasName: string) {
        const ruleDefinition = props.rules.find(c => c.category === category)
        if (!ruleDefinition) {
            console.log('could not find rule definition')
            return
        }

        ruleDefinition.aliases = ruleDefinition.aliases.filter(c => c.name !== aliasName)
        console.log('deleting', ruleDefinition)

        props.updateRule(ruleDefinition)
    }

    const items: CollapseProps['items'] = props.rules.map(d => ({
        key: d.category,
        label: d.category,
        children: <DisplayRuleCategoryDefinition
            key={d.category}
            definition={d}
            onAddNewMatch={(matchName => addNewMatch(d.category, matchName))}
            updateCategoryName={(updatedName) => updateCategoryName(d.category, updatedName)}
            onUpdateMatch={(matchName, update) => updateMatch(d.category, matchName, update)}
            onDeleteMatch={(matchName => deleteMatch(d.category, matchName))}
            onDeleteAlias={(aliasName => deleteAlias(d.category, aliasName))}
        />,
    }))

    return <>
        <Collapse accordion items={items} />
        <AddCategory onAdd={((category) => addNewCategory(category))} />
    </>
}
