import { Button, Card, Col, Divider, Form, Input, InputNumber, Row, Tabs } from 'antd';
import React, { useContext } from 'react';
import { CancelToken } from '../api/HttpClient';
import { TransactionApiV2, UpdateTransactionMetadataRequest } from '../api/TransactionApi';
import { logger, isMobile } from '../utils';
import { ReportAccessTokenComponent } from './ReportAccessTokenComponent';
import { AccountsContext } from '../App';

export interface ReportProps<T> {
    report: T
    updateMetadata: (request: UpdateTransactionMetadataRequest) => void
    yyyy: string
}

export interface ReportsComponentProps<T> {
    accountId: string;
    reportName: string
    renderComponent: (props: ReportProps<T>) => React.ReactNode
}

export interface ReportsTabComponentProps<T> {
    accountId: string;
    reportName: string
    renderComponent: (props: ReportProps<T>) => React.ReactNode
    date: string
}

export interface ReportsTabComponentState<T> {
    creatingReport: boolean
    loadedAccountId?: string
    report?: T;
    error?: string;
    openCard: 'ReportFudge' | 'Token' | 'None'
}

interface ReportFudge {
    description: string;
    amount: number;
    origin: string;
    mmDd: string;
}

const AddReportFudgeComponent = ({ onAddReportFudge }
    : { onAddReportFudge: (reportFudge: ReportFudge) => void }) => {

    return <Form layout="horizontal"
        onFinish={({ description, amount, origin, mmDd }) => onAddReportFudge({ description, amount, origin, mmDd })}
        labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
        <Form.Item label="Date" name="mmDd" rules={[{ required: true, message: 'Date is required' }]}>
            <Input placeholder="Date in 'mm-dd' format" />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Description is required' }]}>
            <Input placeholder="Description of transaction" />
        </Form.Item>
        <Form.Item label="Amount" name="amount" rules={[{ required: true, message: 'Amount is required' }]}>
            <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Origin" name="origin" rules={[{ required: true, message: 'Source is required' }]}>
            <Input placeholder="marc or maisie" />
        </Form.Item>
        <Form.Item name="submit" wrapperCol={{ span: 12, offset: 6 }}>
            <Button type="primary" htmlType="submit">Add</Button>
        </Form.Item>
    </Form>
}

export const ReportsComponent = <T,>(props: ReportsComponentProps<T>) => {
    const { accountId, renderComponent, reportName } = props
    const { accounts } = useContext(AccountsContext);

    const accountDisplayName = (accounts || []).find(a => a.id === accountId)?.name || 'Account not found'

    const years = []
    const thisYear = new Date().getFullYear()
    for (let year = 2023; year <= thisYear; year++) {
        years.push(year + '')
    }

    return <div>
        <h1 style={{ marginTop: '10px' }}>{accountDisplayName} {reportName}</h1>
        <br />
        <Tabs
            defaultActiveKey={thisYear + ''}
            centered
            items={years.map((date, i) => {
                return {
                    label: date,
                    key: date,
                    children: <ReportsTabComponent
                        accountId={accountId}
                        date={date}
                        renderComponent={renderComponent}
                        reportName={reportName}
                    />,
                };
            })}
        />
    </div>
}

class ReportsTabComponent<T> extends React.Component<ReportsTabComponentProps<T>, ReportsTabComponentState<T>> {
    constructor(props: ReportsTabComponentProps<T>) {
        super(props)
        this.state = {
            creatingReport: false,
            openCard: 'None',
        };
    }

    private cancelToken?: CancelToken
    private _isCanceled: boolean = false

    loadReport(accountId: string, date: string, reportName: string) {
        this.cancelToken = new CancelToken();
        this._isCanceled = false
        logger.info('reloading report', accountId, date, reportName);
        TransactionApiV2.reports.get(accountId, date, reportName, this.cancelToken)
            .then(response => {
                if (response.statusCode === 200) {
                    this.setState({
                        report: response.data.report,
                        loadedAccountId: accountId,
                    });
                } else {
                    this.setState({ error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                if (!this._isCanceled) {
                    logger.warn('get transactions cancelled', err);
                }
            });
    }

    componentWillUnmount() {
        this._isCanceled = true;
        this.cancelToken?.cancel();
    }

    recreateReport() {
        const { accountId, date, reportName } = this.props
        this.setState({ creatingReport: true })
        console.log("creating report for", accountId, date, reportName)
        TransactionApiV2.reports.post(accountId, date, reportName)
            .then(response => {
                logger.info('got report', response);
                this.setState({
                    report: response.data.report,
                    loadedAccountId: accountId,
                });
            })
            .catch(err => {
                logger.error('get report error', err);
            })
            .finally(() => {
                this.setState({ creatingReport: false })
            });
    }

    updateTransactionMetadata(update: UpdateTransactionMetadataRequest) {
        const { accountId } = this.props
        this.setState({ creatingReport: true })
        console.log("adding metadata", accountId, update)
        TransactionApiV2.metadata.post(accountId, update, this.cancelToken)
            .then(response => {
                logger.info('updated', response);
            })
            .catch(err => {
                logger.error('get report error', err);
            })
            .finally(() => {
                this.recreateReport()
            });
    }

    addReportFudge(reportFudge: ReportFudge) {
        const { accountId, date, reportName } = this.props
        this.setState({ creatingReport: true })
        console.log("adding report fudge", reportFudge)
        TransactionApiV2.reports.fudge.put({
            accountId,
            reportName,
            timestamp: date + '-' + reportFudge.mmDd,
            description: reportFudge.description,
            origin: reportFudge.origin,
            amount: reportFudge.amount,
        })
            .then(response => {
                logger.info('updated', response);
                this.setState({ openCard: 'None' })
            })
            .catch(err => {
                logger.error('get report error', err);
            })
            .finally(() => {
                this.recreateReport()
            });
    }

    toggleOpenCard(cardType: 'ReportFudge' | 'Token') {
        const { openCard } = this.state
        if (cardType === openCard) {
            this.setState({ openCard: 'None' })
        } else {
            this.setState({ openCard: cardType })
        }
    }

    render() {
        const { accountId, date: yyyy, renderComponent, reportName } = this.props
        const {
            creatingReport,
            loadedAccountId,
            error,
            openCard,
            report,
        } = this.state

        if (error) {
            return <>Error: {error}</>
        }

        if (loadedAccountId !== accountId) {
            this.loadReport(accountId, yyyy, reportName)
            return <>Waiting</>
        }

        const Buttons = () => {

            return <>
                <Button disabled={creatingReport} onClick={() => this.recreateReport()}>Recreate report for {yyyy}</Button>
                <br />
                <Button onClick={() => this.toggleOpenCard('Token')}>{openCard === 'Token' ? 'Close token status' : 'View token status'}</Button>
                <br />
                <Button onClick={() => this.toggleOpenCard('ReportFudge')}>{openCard === 'ReportFudge' ? 'Close report fudge' : 'Add report fudge'}</Button>
            </>
        }

        return <>
            {isMobile && <>
                <Buttons />
                {openCard === 'ReportFudge' &&
                    <Card title="Add report fudge" size="small" style={{ paddingTop: '10px' }}>
                        <AddReportFudgeComponent onAddReportFudge={(...args) => this.addReportFudge(...args)} />
                    </Card>}
                {openCard === 'Token' &&
                    <Card title="Token Status" size="small" style={{ paddingTop: '10px' }}>
                        <ReportAccessTokenComponent accountId={accountId} yyyy={yyyy} recreateReport={() => this.recreateReport()} />
                    </Card>}
            </>}
            {!isMobile && <Row gutter={16}>
                <Col span={8}>
                    <Buttons />
                </Col>
                {openCard === 'ReportFudge' && <Col span={8}>
                    <Card title="Add report fudge" size="small" style={{ width: 500 }}>
                        <AddReportFudgeComponent onAddReportFudge={(...args) => this.addReportFudge(...args)} />
                    </Card>
                </Col>}
                {openCard === 'Token' && <Col span={8}>
                    <Card title="Token Status" size="small" style={{ width: 500 }}>
                        <ReportAccessTokenComponent accountId={accountId} yyyy={yyyy} recreateReport={() => this.recreateReport()} />
                    </Card>
                </Col>}
            </Row>}

            <Divider />
            {!!report && renderComponent({
                yyyy,
                report,
                updateMetadata: (...args) => this.updateTransactionMetadata(...args),
            })}
        </>
    }
}

