import { CancelToken, HttpClient } from "./HttpClient";

export class TransactionApiClient extends HttpClient {
    constructor() {
        super('https://api.account-tracker.maisiesadler.co.uk')
    }
}

export const TransactionApi = {
    accounts: (cancelToken: CancelToken) =>
        new TransactionApiClient().Get<GetAccountsResponse>('/transactions/accounts', cancelToken),
    view: (accountId: string, cancelToken: CancelToken) =>
        new TransactionApiClient().Get<ViewTransactionsResponse>(`/transactions/view/${accountId}`, cancelToken),
    mapped: (accountId: string, cancelToken: CancelToken) =>
        new TransactionApiClient().Get<GetMappedTransactionsResponse>(`/view/${accountId}`, cancelToken),
    recalculateMappedGroup: (accountId: string, group: string) =>
        new TransactionApiClient().Get<GetMappedTransactionsResponse>(`/view/${accountId}/recalculate/${group}`),
    transactions: {
        updateCategory: (accountId: string, group: string, transactionId: string, category: string) =>
            new TransactionApiClient().Post<{ category: string }>(`/view/${accountId}/transactions/${group}/${transactionId}`, { category }),
        delete: (accountId: string, group: string, transactionId: string) =>
            new TransactionApiClient().Delete<void>(`/transactions/${accountId}/${group}/${transactionId}`),
        manual: (accountId: string, group: string, transaction: NewTransaction) =>
            new TransactionApiClient().Post<void>(`/transactions/manual/${accountId}/${group}`, transaction),
    },
    bank: {
        loginStatus: (cancelToken: CancelToken) =>
            new TransactionApiClient().Get<BankLoginStatusV2>('/transactions/bank/login', cancelToken),
        exchangeCode: (code: string, cancelToken?: CancelToken) =>
            new TransactionApiClient().Post<BankLoginStatusV2>('/transactions/bank/login', { code }, cancelToken),
        refresh: (cancelToken?: CancelToken) =>
            new TransactionApiClient().Get<BankLoginStatusV2>('/transactions/bank/refresh', cancelToken),
        updateTransactions: (group: string) =>
            new TransactionApiClient().Get<UpdateTransactionsResponse>(`/transactions/bank/transactions?date=${group}`),
    },
    rules: {
        get: (accountId: string, cancelToken: CancelToken) =>
            new TransactionApiClient().Get<{ rules: Rule[] }>(`/rules/${accountId}`, cancelToken),
        post: (accountId: string, rules: Rule[], cancelToken?: CancelToken) =>
            new TransactionApiClient().Post<{ rules: Rule[] }>(`/rules/${accountId}`, { rules }, cancelToken),
    },
}

export const TransactionApiV2 = {
    accounts: (cancelToken: CancelToken) =>
        new TransactionApiClient().Get<{ id: string, name: string }[]>('/v2/accounts', cancelToken),
    bank: {
        loginStatus: (cancelToken: CancelToken) =>
            new TransactionApiClient().Get<BankLoginStatusV2>('/v2/bank/login', cancelToken),
        exchangeCode: (code: string, cancelToken?: CancelToken) =>
            new TransactionApiClient().Post<BankLoginStatusV2>('/v2/bank/login', { code }, cancelToken),
        refresh: (accountId: string, cancelToken?: CancelToken) =>
            new TransactionApiClient().Get<BankLoginStatusV2>(`/v2/bank/${accountId}/refresh`, cancelToken),
        updateTransactions: (accountId: string, reloadYyyyMm: string) =>
            new TransactionApiClient().Get<UpdateTransactionsResponse>(`/v2/bank/${accountId}/transactions?date=${reloadYyyyMm}`),
    },
    reports: {
        get: (accountId: string, yyyyOrYyyyMm: string, reportName: string, cancelToken: CancelToken) =>
            new TransactionApiClient().Get<{ rules: Rule[] }>(`/v2/reports/${accountId}/${yyyyOrYyyyMm}/${reportName}`, cancelToken),
        post: (accountId: string, yyyyOrYyyyMm: string, reportName: string, cancelToken?: CancelToken) =>
            new TransactionApiClient().Post<{ rules: Rule[] }>(`/v2/reports/${accountId}/${yyyyOrYyyyMm}/${reportName}`, {}, cancelToken),
        fudge: {
            put: (request: AddReportFudgeRequest, cancelToken?: CancelToken) =>
                new TransactionApiClient().Put<{ rules: Rule[] }>(`/v2/reports/fudge`, request, cancelToken),
        }
    },
    metadata: {
        post: (accountId: string, request: UpdateTransactionMetadataRequest, cancelToken?: CancelToken) =>
            new TransactionApiClient().Post<{ rules: Rule[] }>(`/v2/metadata`, { accountId, ...request }, cancelToken),
    },
    rules: {
        get: (accountId: string, reportName: string, cancelToken?: CancelToken) =>
            new TransactionApiClient().Get<{ rules: RuleCategoryDefinition[] }>(`/v2/rule/${accountId}/${reportName}`, cancelToken),
        post: (accountId: string, reportName: string, rule: RuleCategoryDefinition, existingCategoryName: string | undefined, cancelToken?: CancelToken) =>
            new TransactionApiClient().Post<{ rules: Rule[] }>(`/v2/rule/${accountId}/${reportName}`, { rule, existingCategoryName }, cancelToken),
    },
}

export interface NewTransaction {
    timestamp: string
    description: string
    amount: number
    source: string
}

export interface Rule {
    type: 'source' | 'description';
    match: string;
    category: string;
}

export interface AccessTokenInfo {
    account_id: string,
    access_token_expiry: string,
}

export interface BankLoginStatusV2 {
    auth_url: string
    accounts: AccessTokenInfo[]
}

export interface BankLoginStatusV1 {
    auth_url: string
    access_token_expiry: string
}

export interface UpdateTransactionsResponse {
    results: {
        group: string;
        transactions: number;
        updated: number;
    }[]
}

export interface GetAccountsResponse {
    message: string
    results: { accounts: { id: string, name: string }[] }
}

export interface ViewTransactionsResponse {
    account_id: string
    transactions: { [key: string]: Transaction[] }
}

export interface Transaction {
    id: string
    timestamp: string
    description: string
    transactionType: string // CREDIT or DEBIT
    transactionCategory: string
    amount: number // credit transfers are +ve
    source: string
}

export interface GetMappedTransactionsResponse {
    // group
    [key: string]: {
        lastRecalculated: string
        transactions: TransactionsAndCategory[]
        // category: total
        totals: { [key: string]: number }
    }
}

export interface TransactionsAndCategory {
    transaction: Transaction
    category: string
}

export interface AddReportFudgeRequest {
    accountId: string;
    timestamp: string;
    description: string;
    amount: number;
    origin: string;
    reportName: string;
}

export interface RuleCategoryDefinition {
    category: string;
    matches: {
        name: string;
        type?: string;
    }[]
    aliases: {
        name: string
    }[]
}

export interface UpdateTransactionMetadataRequest {
    yyyyMm?: string;
    transactionId?: string;
    fromDate?: string;
    toDate?: string;
    metadataKey: string;
    metadataValue: string;
}
